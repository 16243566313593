<template>
  <!--  会员职称待清洗-->
  <div class="hospitalCleaning">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div class="hospital_table">
        <a-button type="primary"  @click="refresh">刷新</a-button>
        <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" :rowKey="(record,index) => {return index}"   :pagination="false"
        >
        <span slot="input" slot-scope="text,item">
          <a-select style="width: 180px;margin-right: 10px" v-model="item.type" placeholder="选择身份" @change="typeSelect">
              <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.value">
                {{ list.label }}
              </a-select-option>
            </a-select>
          <a-select style="width: 180px;" v-model="item.job" placeholder="选择职称" >
              <a-select-option v-for="list in titleInfo" :key="list.id" :value="list.job_title">
                {{ list.job_title }}
              </a-select-option>
            </a-select>
        </span>
          <span slot="operate" slot-scope="text,item">
            <a  style="margin-right: 10px" @click="saveOk(item.type,item.job,item.job_title)">保存</a>
            <a @click="blankOk(item.job_title)">置空</a>
        </span>
        </a-table>
        <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="paginationChange" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {getTitleCleans, getTitles, putTitleClean, putTitleCleans} from "@/service/dataCleaned_api";

export default {
  name: "titleCleaning",
  data(){
    return{
      spinning:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      typeInfo:[
        {
          value:1001,
          label:'医生'
        },
        {
          value:1002,
          label:'护士'
        },
        {
          value:1003,
          label:'医技'
        },
        {
          value:1004,
          label:'药师'
        },
      ],
      titleInfo:[],
      columns:[
        {
          title: '非职称名单的注册职称',
          dataIndex: 'job_title',
        },
        {
          title: '注册数量',
          dataIndex: 'count',
        },
        {
          title: '名称修改为',
          scopedSlots: { customRender: 'input' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[
      ],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"会员职称待清洗")
  },
  mounted() {
    this.getTitleClean()
  },
  methods:{
    async getTitleClean(pageNum){
      this.spinning = true
      const Info = await getTitleCleans(pageNum)
      if (Info.code=== 0){
        this.InfoTable = Info.data.wySs
        this.pagination.total = Info.data.count
      }else {
        this.$message.warning('接口连接出错' + Info.message)
      }
      this.spinning = false
    },
    //职称选择
    async typeSelect(value){
      const Info = await getTitles(value)
      this.titleInfo = Info.data.row
    },
    paginationChange(e){
      this.getTitleClean(e)
    },
    async saveOk(e,job,title){
      if (e !== '' & e !== undefined & e !== null & job !== '' & job !== undefined && job !== null ){
        const data = {
          type : e,
          job : job
        }
        const save =await putTitleCleans(title,data)
        if (save.code === 0){
          await this.getTitleClean(this.pagination.current)
          this.$message.success('保存成功')
        }
      }else {
        this.$message.error('请选择职称')
      }
    },
    //置空
    async blankOk(name){
      const save =await putTitleClean(name)
      if (save.code === 0){
        await this.getTitleClean(this.pagination.current)
        this.$message.success('已置空')
      }else {
        this.$message.warning('接口出现错误' + save.message)
      }
    },
    //刷新
    refresh(){
      this.getTitleClean()
    }
  }
}
</script>

<style lang="scss" scoped>
.hospitalCleaning{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .hospital_table{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    background-color: white;
  }
}
</style>
